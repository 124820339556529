import * as React from 'react';
import * as styles from '../../pages/sign-in.module.css';

import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import {
	Box,
	Button,
	FormControl,
	FormLabel,
	HStack,
	Heading,
	Input,
	InputGroup,
	InputRightElement,
	Link,
	Stack,
	Text,
	VStack,
	useToast,
} from '@chakra-ui/react';
import { gql, useMutation } from '@apollo/client';

import { GET_USER } from '../useAuth';
import { useState } from 'react';

const LOG_IN = gql`
	mutation logIn($login: String!, $password: String!) {
		loginWithCookies(input: { login: $login, password: $password }) {
			status
		}
	}
`;

const SignInPage: React.FC = () => {
	const toast = useToast();

	// Password toggle handler
	const [showPassword, setShowPassword] = useState(false);
	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const [logIn, { loading, error }] = useMutation(LOG_IN, {
		refetchQueries: [
			{
				query: GET_USER,
			},
		],
	});

	const errorMessage = error?.message || '';
	const isEmailValid =
		!errorMessage.includes('empty_email') &&
		!errorMessage.includes('empty_username') &&
		!errorMessage.includes('invalid_email') &&
		!errorMessage.includes('invalid_username');
	const isPasswordValid =
		!errorMessage.includes('empty_password') &&
		!errorMessage.includes('incorrect_password');

	function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();
		const data: any = new FormData(event.currentTarget);

		const values = Object.fromEntries(data);

		const email = values.login;
		const password = values.password;

		logIn({
			variables: {
				login: email,
				password,
			},
		})
			.then(() => {
				//@ts-ignore
				window.dataLayer = window.dataLayer || [];
				//@ts-ignore
				window.dataLayer.push({
					event: 'login',
					authentication_method: 'email',
					user_id: email,
				});
			})
			.catch((error) => {
				if (errorMessage.includes('pending_approval')) {
					toast({
						status: 'error',
						description:
							'Your email is pending approval. Please try again later.',
					});
				}
			});
	}

	return (
		<Box
			w='100%'
			h='100vh'
			bgImage='/dm-signin-hero.webp'
			bgRepeat='no-repeat'
			bgSize='cover'
			bgPos='center'>
			<div className={styles.overlay} />
			<Stack
				position='relative'
				zIndex='2'
				h='inherit'
				justifyContent='center'
				alignItems='center'>
				<VStack
					bg='white'
					minW={{ base: 'auto', md: '500px' }}
					borderRadius='8px'
					p='36px'
					gap='30px'>
					<VStack gap='20px'>
						<Heading
							as='h1'
							fontWeight='400'>
							Sign In
						</Heading>
						<HStack fontSize='14px'>
							<Text>Don’t have an account?</Text>

							<Link
								href='/registration'
								fontWeight={700}>
								Register here
							</Link>
						</HStack>
					</VStack>

					<form
						method='POST'
						onSubmit={handleSubmit}
						style={{ width: '100%' }}>
						<VStack
							gap='20px'
							alignItems='center'
							w='100%'
							>
							<FormControl>
								<FormLabel
									htmlFor='email'
									pb='16px'>
									Email
								</FormLabel>
								<Input
									id='log-in-email'
									variant='outline'
									height='40px'
									p='1.8rem 1rem'
									textColor='#1a202c'
									borderColor='#1a202c'
									focusBorderColor='#dca672'
									type='email'
									name='login'
									autoComplete='username'
									required
								/>
								{!isEmailValid ? (
									<Text
										color='red'
										className='error-message'>
										Invalid email. Please try again.
									</Text>
								) : null}
							</FormControl>
							<FormControl>
								<FormLabel
									htmlFor='password'
									pb='16px'>
									Password
								</FormLabel>
								<InputGroup size='md'>
									<Input
										variant='outline'
										textColor='#1a202c'
										borderColor='#1a202c'
										focusBorderColor='#dca672'
										padding='1.8rem 1rem'
										type={showPassword ? 'text' : 'password'}
										id='log-in-password'
										name='password'
										autoComplete='current-password'
									/>
									<InputRightElement width='4.5rem'>
										<Button
											top='9px'
											background='transparent'
											_hover={{ background: 'transparent' }}
											size='sm'
											onClick={toggleShowPassword}>
											{showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
										</Button>
									</InputRightElement>
								</InputGroup>
								{!isPasswordValid || !isEmailValid ? (
									<Text
										pl={2}
										pb={2}
										color='red'
										className='error-message'>
										Wrong email or password
									</Text>
								) : null}
							</FormControl>
							<Button
								type='submit'
								variant='outline'
								size='lg'
								fontWeight='600'>
								{loading ? 'SIGNING IN...' : 'SIGN IN'}
							</Button>
							<Link
								href='/password-reset'
								fontSize='14px'
								fontWeight={700}>
								Forgot password?
							</Link>
						</VStack>
					</form>
				</VStack>
			</Stack>
		</Box>
	
	);
};

export default SignInPage;
